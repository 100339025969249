<script setup lang="ts">
import { CmsSlot, Media, Product } from "@shopware-pwa/types";

import { ElementConfig } from "@shopware-pwa/composables-next";

type ProductlookElementConfig = {
  media: ElementConfig<string>;
  title: ElementConfig<string>;
  subtitle: ElementConfig<string>;
  text: ElementConfig<string>;
  products: ElementConfig<string[]>;
};

type CmsElementProductlook = CmsSlot & {
  type: "productlook";
  config: ProductlookElementConfig;
  data: {
    mediaId: string | null;
    media: null | Media;
    products: Product[];
    apiAlias: "cms_productlook";
  };
};

const props = defineProps<{
  content: CmsElementProductlook;
}>();

const { getConfigValue } = useCmsElementConfig(props.content);

const image = computed(() => getConfigValue("media"));
const title = computed(() => getConfigValue("title"));
const subtitle = computed(() => getConfigValue("subtitle"));
const text = computed(() => getConfigValue("text"));
const products = computed(() => props.content?.data?.products?.products);
</script>

<template>
  <div>
    <div class="c-width-default auto-rows-min sm:grid sm:grid-cols-5 xl:grid-cols-3">
      <div class="col-span-2 row-span-2 xl:col-auto">
        <SharedCmsImage
          :src="image?.url"
          :crop="image?.crop"
          :focus-point="image?.focusPoint"
          :alt="title"
          width="660"
          height="990"
          class-img="h-full object-cover"
        />
      </div>
      <div
        v-if="text || subtitle || text"
        class="s-gutter col-span-3 max-w-2xl py-6 sm:py-12 lg:col-span-3 xl:col-span-2"
      >
        <h2 v-if="title" class="text-maas-typography-headline-primary text-h2">{{ title }}</h2>
        <h3 v-if="subtitle" class="text-maas-typography-headline-primary text-h3">{{ subtitle }}</h3>
        <div v-if="text" v-html="text" class="richtext mt-6" />
      </div>
      <SharedFeed
        v-if="products"
        class="s-gutter col-span-5 py-6 lg:col-span-3 xl:col-span-2"
        type="slider"
        :products="products"
      />
    </div>
  </div>
</template>
